import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import usersRequest from '@/api/common/usersRequest';
import { debounce } from 'vue-debounce';
import { mapState } from "vuex";
import moment from "moment";
import { periodUnitCategories } from '@/constant/config.js';
const queryString = require('query-string');
export default {
    extends: baseComponent,
    components: {
        detailUser: lazyLoadComponent({
            componentFactory: () => import("@/views/users/DetailUserInfo"),
            loading: SkeletonBox,
        }),
        requestFromUserDetail: lazyLoadComponent({
            componentFactory: () => import("@/views/users/RequestFromUserDetail"),
            loading: SkeletonBox,
        }),
    },

    data() {
        return {
            debounceFnc: null,
            listData: {},
            detailModel: null,
            packageInfoDictionary: {},
            pagination: {
                announced: "",
                queryString: '',
                orderBy: 'CREATED_AT',
                directionSort: 'ASC',
                pageIndex: 1,
                pageSize: 100,
                outRowsNumber: 0,
            },
            isLoadingList: false,
            periodUnitCategories: periodUnitCategories,
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: (state) => state.common.listServicePack,
        }),
    },

    created() {
        this.getData(this.pagination);
        this.debounceFnc = debounce(() => {
            this.getData(this.pagination);
        }, 1000);
    },

    methods: {

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refreshList':
                    this.getData(this.pagination);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Get data
        getData(request) {
            this.isLoadingList = true;
            usersRequest.getUserRequest(queryString.stringify(request))
                .then((res) => {
                    try {
                        let responseData = res.data;
                        let pagingItem = res.data.pagingItem;
                        this.listData = responseData.data;
                        this.pagination.orderBy = pagingItem.orderBy;
                        this.pagination.directionSort = pagingItem.directionSort;
                        this.pagination.pageIndex = pagingItem.pageIndex;
                        this.pagination.pageSize = pagingItem.pageSize;
                        this.pagination.outRowsNumber = pagingItem.outRowsNumber;
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    setTimeout(() => {
                        this.isLoadingList = false;
                    }, 500);
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    setTimeout(() => {
                        this.isLoadingList = false;
                    }, 500);
                });
        },

        sortList() {

        },

        // Sort list
        sortCurrentList(element) {
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            let request = {
                orderBy: element,
                directionSort: this.pagination.directionSort,
                pageIndex: 1,
                pageSize: this.pagination.pageSize,
                outRowsNumber: this.pagination.outRowsNumber,
            };
        },

        // Search list
        searchList() {
        },
        getPackageInfo(elementServicePackage) {
            if (this.packageInfoDictionary.hasOwnProperty(elementServicePackage)) return this.packageInfoDictionary[elementServicePackage];

            let packageInfo = this.listServicePack.find(x => x.id === elementServicePackage.toLowerCase());
            if (packageInfo && packageInfo !== null) {
                this.packageInfoDictionary[elementServicePackage] = packageInfo;
                return packageInfo;
            }
            else {
                //console.log("elementServicePackage", elementServicePackage);
                return {
                    name: "---",
                    description: "---",
                    serviceType: 0,
                    numberCard: "---"
                }
            }
        },
        // Pagination size change
        handleSizeChange(pageSize) {
            let request = {
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
                pageIndex: 1,
                pageSize: pageSize,
                outRowsNumber: this.pagination.outRowsNumber,
            };
            this.getData(request);
        },

        // Pagination page change
        handleCurrentChange(element) {
            let request = {
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
                pageIndex: element,
                pageSize: this.pagination.pageSize,
                outRowsNumber: this.pagination.outRowsNumber,
            };
            this.getData(request);
        },
        viewUserRequestModal(dataItem) {
            this.isLoadingList = true;
            this.$set(this, "detailModel", dataItem);
            this.$nextTick(() => {
                $("#detailUserRequestModal").modal({ backdrop: 'static', keyboard: true, });
                this.isLoadingList = false;
            });
        },
        closeUserRequestModal() {
            $("#detailUserRequestModal").modal("hide");
            this.$set(this, "detailModel", null);
        },
        getStaffItem(staffCare) {
            let staffItem = this.listStaff.find(x => x.email === staffCare);
            if (staffItem) return staffItem;
            else return {};
        }
    }
}