import axios from "axios";
import { requestUserConfig, EXECUTE_RESULT } from "@/constant/config";

export default {

    // Get user request
    getUserRequest: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${requestUserConfig.getUserRequestUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    // Get user request detail
    getUserRequestDetail(requestId, request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${requestUserConfig.getUserRequestDetailUrl.format(requestId)}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
}